import { v4 as uuid4 } from 'uuid';
import { record } from '../../recorder';
import { initalizeConsoleLog } from '../lib/collectLog';
import { initalizeNetworkLog } from '../lib/collectNetwork'
import { getBrowserData } from '../lib/getMetadata';
import Api from './api';
import {
    DEFAULT_SESSION_REPLAY_BLOCK_CLASS,
    DEFAULT_SESSION_REPLAY_MASK_CLASS,
    CAPTURE_IMAGES_INLINE,
    DEFAULT_SESSION_THROTTLE,
    CAPTURE_INLINE_STYLESHEET,
    MASK_INPUT_CLASS,
    SDK_VERSION,
    UE_LAST_UPLOAD,
    UE_SESSION_ID,
    USER_IDENTIFIER,
    UE_TAB,
    setDurableStorageItem,
    clearDurableStorageItem,
    USER_PROPERTIES,
    getDurableStorageItem,
    CUSTOM_METADATA,
    NORMAL_POLL_FREQUENCY,
    LIVE_POLL_FREQUENCY,
} from '../utils';
import { UserIdleTracker } from './userIdleTracker';
import { processEvent } from '../lib/processUEEvents';
import { hashFunction } from '../lib/hashFunction';
import { ConsoleLogTypes } from '../types';
import { pushRecordingStopped } from '../lib/collectEvents';

type listenerHandler = () => void;

export let isRecording = false
export let sessionEvents: Array<object> = []
export let logEvents: Array<object> = []
export let lastUploadedTime: number;
export let sequenceNumber = 0;

export const resetSessionEvents = (): void => {
    sessionEvents = []
}
export const resetLogEvents = (): void => {
    logEvents = []
}

export const setLastUploadedTime = () => {
    lastUploadedTime = Math.floor(Date.now() / 1000)
    localStorage.setItem(UE_LAST_UPLOAD, lastUploadedTime.toString())
}

export const increaseSequenceNumber = () => {
    sequenceNumber = sequenceNumber + 1
}

export interface UserExperiorOptions {
    sessionReplay: SessionReplayOptions
}

interface SessionReplayOptions {
    maskAllInputs?: boolean;
    maskInputOptions?: MaskInputOptions;
    captureMouseMove?: boolean;
    captureNetworkLogs?: boolean;
    captureConsoleLogs?: boolean;
    enableUserId?: boolean;
    livePolling?: boolean;
    recordCrossOriginIframes?: boolean;
    captureConsoleLogTypes: Array<ConsoleLogTypes>;
    isChildIframe?: boolean;
}

type MaskInputOptions = Partial<{
    color: boolean;
    date: boolean;
    'datetime-local': boolean;
    email: boolean;
    month: boolean;
    number: boolean;
    range: boolean;
    search: boolean;
    tel: boolean;
    text: boolean;
    time: boolean;
    url: boolean;
    week: boolean;
    // unify textarea and select element with text input
    textarea: boolean;
    select: boolean;
    password: boolean;
}>;

export default class UserExperior {
    private projectKey: string;
    private startSessionHandler: () => void;
    private stopSessionHandler: () => void;
    private sessionToken: string;
    private recorderInstance: listenerHandler | undefined;
    private api: Api | undefined;
    private logIntervalInstance: NodeJS.Timeout | undefined;
    private userIdleTrackerInstance: UserIdleTracker | undefined;
    userId: string | null;
    userProperties: object | {};
    private tabId: string;
    private captureMouseMove: boolean;
    private captureConsoleLogs: boolean;
    private captureNetworkLogs: boolean;
    private enableUserId: boolean;
    options: UserExperiorOptions;
    private recordCrossOriginIframes: boolean;
    private releaseVersion: string | null;
    private captureConsoleLogTypes: Array<ConsoleLogTypes>;
    private isChildIframe: boolean;

    constructor(
        projectKey: string,
        options: UserExperiorOptions,
        releaseVersion?: string | null,
    ) {
        this.projectKey = projectKey
        this.sessionToken = ''
        this.userId = null
        this.userProperties = {}
        this.tabId = uuid4()
        this.options = options
        this.startSessionHandler = this.startRecording.bind(this)
        this.stopSessionHandler = this.stopRecording.bind(this)
        this.captureMouseMove = options.sessionReplay.captureMouseMove ? options.sessionReplay.captureMouseMove : false;
        this.captureConsoleLogs = options.sessionReplay.captureConsoleLogs == false ? false : true;
        this.captureNetworkLogs = options.sessionReplay.captureNetworkLogs == false ? false : true;
        this.enableUserId = options.sessionReplay.enableUserId || false;
        this.recordCrossOriginIframes = options.sessionReplay.recordCrossOriginIframes || true;
        this.releaseVersion = releaseVersion || null;
        this.captureConsoleLogTypes = options.sessionReplay.captureConsoleLogTypes;
        this.isChildIframe = options.sessionReplay.isChildIframe ?? false;
    }

    async startRecording(): Promise<void> {
        // User will call this menthod to initiate the recording
        // If session exists get the session id else set new session id
        if (!isRecording) {
            if (this.isChildIframe) {
                // if this is recording of child iframe, then no need to start a session
                // only need to start rrweb recording
                this.initalizeSessionRecording();
                return;
            }

            this.sessionToken = uuid4()
            const existingSessionId = localStorage.getItem(UE_SESSION_ID) || sessionStorage.getItem(UE_SESSION_ID)
            const existingLastUpload = localStorage.getItem(UE_LAST_UPLOAD)
            const currentTime = Math.floor(Date.now() / 1000)
            let userId = 'Anonymous'
            const userIdString = `${window.navigator.userAgent}-${window.navigator.platform}-${window.innerHeight}-${window.innerWidth}-${Date.now()}-${this.sessionToken.split('-')[0]}`
            if (this.enableUserId) {
                const hashedUser = hashFunction(userIdString)
                userId = `Anon-${hashedUser}`
            }

            // adding new session id if:
            // - session id not exists or
            // - closed all tabs or closed the browser
            if (!existingSessionId || (existingLastUpload && currentTime - parseInt(existingLastUpload) >= DEFAULT_SESSION_THROTTLE)) {
                localStorage.setItem(UE_SESSION_ID, this.sessionToken)
                sessionStorage.setItem(UE_SESSION_ID, this.sessionToken)
            } else {
                this.sessionToken = existingSessionId;
            }

            // If tab id exists get the tab id else set the tab id
            const existingTabId = sessionStorage.getItem(UE_TAB)
            if (!existingTabId) {
                sessionStorage.setItem(UE_TAB, this.tabId)
            } else {
                this.tabId = existingTabId
            }

            //initilizing api by passing projectKey and sessionToken
            this.api = new Api(this.projectKey, this.sessionToken, this.tabId, this.releaseVersion)

            //sending inital set of data
            const sessionCreated = await this.api.createSession({
                sessionMetaData: getBrowserData(),
                identifier: getDurableStorageItem(USER_IDENTIFIER) || userId,
                userProperties: JSON.parse(getDurableStorageItem(USER_PROPERTIES) || "null"),
                customMetadata: JSON.parse(getDurableStorageItem(CUSTOM_METADATA) || "null"),
                sdkVersion: SDK_VERSION,
                releaseVersion: this.releaseVersion,
            })

            //initalize session replay, collecting console logs and network logs
            if (sessionCreated) {
                this.initalizeSessionRecording();
            } else {
                console.error('Failed to create session, recording not initialized.');
            }
        }
    }

    // TO:DO move away from object to map<string,string>
    setUserIdentifier(id: string, userProperties: object = {}): void {
        if (id === null || id === undefined || typeof id !== 'string') {
            console.error('UserExperior: user id is missing or wrong type (string is expected). Please check documentation for more information.')
            return;
        }
        const userPropertiesKeys = Object.keys(userProperties);
        if (userPropertiesKeys.length > 0 && Object.keys(userProperties).length !== Object.keys(userProperties).filter((key) => typeof key === 'string').length) {
            console.error('UserExperior: user properties expect key value pairs of string only. Please check documentation for more information.')
            return;
        }
        setTimeout(() => {
            if (id) {
                this.userId = id
                this.userProperties = userProperties
                this.api?.sendUser({ user: this.userId, userProperties: this.userProperties})
                setDurableStorageItem(USER_IDENTIFIER, id)
                setDurableStorageItem(USER_PROPERTIES, JSON.stringify(userProperties))
                // localStorage.setItem(USER_IDENTIFIER, id)
                // sessionStorage.setItem(USER_IDENTIFIER, id)
            }
        }, 1);
    }

    setUserProperties(userTraits: object): void {
        if (Object.keys(userTraits).length) {
            //if user identifier intialized then sending user traits
            this.api?.sendUser({ user: this.userId, userProperties: userTraits })
        }
    }

    initalizeSessionRecording() {
        isRecording = true

        // if this is recording the child iframe, then only need to
        // start rrweb recording, nothing else

        if (!this.isChildIframe) {
            //user activity tracker instance
            this.userIdleTrackerInstance = new UserIdleTracker(this.startSessionHandler, this.stopSessionHandler)
            setLastUploadedTime()
            if (this.captureConsoleLogs) {
                initalizeConsoleLog(this.captureConsoleLogTypes)
            }
            if (this.captureNetworkLogs) {
                initalizeNetworkLog()
            }
        }

        this.recorderInstance = record({
            emit(event) {
                processEvent(event);
                sessionEvents.push(event);
            },
            recordCrossOriginIframes: this.recordCrossOriginIframes,
            blockClass: DEFAULT_SESSION_REPLAY_BLOCK_CLASS,
            maskTextClass: DEFAULT_SESSION_REPLAY_MASK_CLASS,
            maskAllInputs: this.options.sessionReplay.maskAllInputs,
            maskInputOptions: this.options.sessionReplay.maskInputOptions,
            inlineImages: CAPTURE_IMAGES_INLINE,
            inlineStylesheet: CAPTURE_INLINE_STYLESHEET,
            maskInputClass: MASK_INPUT_CLASS,
            sampling: { input: 'last', mousemove: this.captureMouseMove }
        });

        if (!this.isChildIframe) {
            if (this.options.sessionReplay.livePolling) {
                this.logIntervalInstance = setInterval(() => this.api?.sendLogs(false, this.stopRecording.bind(this)), LIVE_POLL_FREQUENCY)
            } else {
                this.logIntervalInstance = setInterval(() => this.api?.sendLogs(false, this.stopRecording.bind(this)), NORMAL_POLL_FREQUENCY)
            }
        }
    }

    // TO:DO move away from object to map<string,string>
    addMetaData(properties: object = {}): void {

        const metaDataProperties = Object.keys(properties);
        if (metaDataProperties.length > 0 && Object.keys(properties).length !== Object.keys(properties).filter((key) => typeof key === 'string').length) {
            console.error('UserExperior: metadata properties expect key value pairs of string only. Please check documentation for more information.');
            return;
        }

        this.api?.sendCustomMetaData({ properties: properties });

        setTimeout(() => {
            setDurableStorageItem(CUSTOM_METADATA, JSON.stringify(properties))
        }, 1);
    }

    logEvent(event: string, eventDetails: object = {}): void {
        //sending log event
        logEvents.push({
            event: event,
            event_details: eventDetails,
            epochTime: Math.floor(Date.now() / 1000),
            timestamp: Date.now()
        })
    }

    unsetUserIdentifier(): void {
        this.clearIdentifier();
    }

    stopRecording(clearIdentifier = true): void {
        //stop complete recording
        if (isRecording) {
            //removing session id from localstorage
            localStorage.removeItem(UE_SESSION_ID)
            sessionStorage.removeItem(UE_SESSION_ID)

            //clearing rrweb record instance
            if (this.recorderInstance) {
                this.recorderInstance()
            }
            //setting isRecording false to not capture network and console logs
            isRecording = false

            //removing event tracking listeners
            // removeEventListeners()

            //clearing log sending interval
            if (this.logIntervalInstance) {
                clearInterval(this.logIntervalInstance)
            }

            //clearing log user activity tracker event listeners
            if (this.userIdleTrackerInstance) {
                this.userIdleTrackerInstance.cleanUpTracker()
            }

            if (clearIdentifier) {
                // Dont clear the identifier if the session is stopped after inactivty 
                this.clearIdentifier();
            }
        }
    }

    restartSession(clearID = false): void {
        //change the session id and reinitialize session recording
        if (isRecording) {
            //force sending the logs of previous session
            this.api?.sendLogs(true, this.stopRecording.bind(this));

            //removing session id from localstorage
            localStorage.removeItem(UE_SESSION_ID)
            sessionStorage.removeItem(UE_SESSION_ID)

            //clearing rrweb record instance
            if (this.recorderInstance) {
                this.recorderInstance()
            }
            //setting isRecording false to not capture network and console logs
            isRecording = false

            //removing event tracking listeners
            // removeEventListeners()

            //clearing log sending interval
            if (this.logIntervalInstance) {
                clearInterval(this.logIntervalInstance)
            }

            //clearing log user activity tracker event listeners
            if (this.userIdleTrackerInstance) {
                this.userIdleTrackerInstance.cleanUpTracker()
            }
            if (clearID) {
                this.clearIdentifier();
            }

            //restarting the session recording
            this.startRecording();
        }
    }

    getSessionDetails(): any { 
        return {
            sessionId: this.sessionToken,
            tabId: this.tabId,
            versionKey: this.projectKey
        }
    }

    triggerStopRecording(): void {
        //push event for recording-stopped
        if (isRecording) {
            pushRecordingStopped(document.location.href, document.title);
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.api?.sendLogs(true, ()=>{}); 
            this.stopRecording();
        }
    }

    clearIdentifier(): void {
        if (this.userId) {
            this.userId = null
        }

        clearDurableStorageItem(USER_IDENTIFIER)
        clearDurableStorageItem(USER_PROPERTIES)
        clearDurableStorageItem(CUSTOM_METADATA)
    }


}
